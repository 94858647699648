
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

























.spec,
[class*='spec--'] {
  position: relative;
}

.spec__list {
  @extend %list-nostyle;

  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem 0;

  @include mq('s') {
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    gap: 0 1rem;
  }
}

.spec__list__item {
  padding: 1rem 2.5rem;
  background-color: $gray-lightest;
  border-radius: 0.8rem;

  @include mq('s') {
    padding: 2.5rem;
  }
}

.spec__label {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-family: $ff-default;

  @include mq('s') {
    margin-bottom: 1rem;
  }
}

.spec__value {
  font-weight: 700;
  font-size: 2.6rem;
  color: $c-pink-medium;

  ::v-deep p {
    margin: 0;
  }
}
